import React, { ReactNode } from 'react';
import styles from './style.module.css';

type Props = {
  onClick: () => void;
  children: ReactNode | string;
  className?: string;
  variant?: string;
  style?: React.CSSProperties;
}

export const Button = ({
  style = {},
  children,
  onClick,
  className = '',
  variant = 'secondary',
}: Props) => (
  <button
    type="button"
    onClick={onClick}
    className={`${styles.button} ${styles[variant]} ${className}`}
    style={style}
  >
    {children}
  </button>
);
