/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { CustomerData } from '../PaymentMethods/commonTypes';
import { getData } from '../../../services/httpget';
import { ResponseDataDeal } from '../../../type/type';

import style from './CustomerForm.module.scss';

type Props = {
  onSubmit: Dispatch<SetStateAction<boolean>>;
  setCustomerData: Dispatch<SetStateAction<CustomerData>>;
  customerFormData: CustomerData;
  title: string;
  tariffSlug: string | null;
  onResponseDeal: (res: ResponseDataDeal) => void;
};
export const CustomerForm = (props: Props) => {
  const {
    onSubmit,
    customerFormData,
    setCustomerData,
    title,
    tariffSlug,
    onResponseDeal,
  } = props;

  const [btnInActive, setButtonInActive] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [telegram, setTelegram] = useState('');

  useEffect(() => {
    const allFieldsFilled = name && email && phone && telegram;
    setButtonInActive(!allFieldsFilled || !customerFormData.termsChecked);
  }, [name, email, phone, telegram, customerFormData.termsChecked]);

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const { value, name } = target;

    if (target.type !== 'checkbox') {
      if (name === 'email') {
        setEmail(value);
      } else if (name === 'customerName') {
        setName(value);
      } else if (name === 'phoneNumber') {
        setPhone(value);
      } else if (name === 'telegram') {
        setTelegram(value);
      }
    }

    let usedValue: boolean | string = value;
    if (target.type === 'checkbox') {
      usedValue = target.checked;
    }

    setCustomerData((prev) => ({ ...prev, [name]: usedValue }));
  };

  const getNewOrder = () => {
    try {
      if (tariffSlug) {
        const queryString = new URLSearchParams({
          email,
          first_name: name.slice(0, 20),
          last_name: name.slice(0, 20),
          phone,
          telegram,
          tariffSlug,
        });
        getData(`/api/newOrder?${queryString}`).then((res) => {
          onResponseDeal(res);
        });
      }
    } catch (error) { /* empty */ }
  };

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    onSubmit(true);
    getNewOrder();
  };

  return (
    <form className={`${style.flexBox} ${style.wrapper}`} onSubmit={handleSubmit}>
      <h1>{`Тариф ${title}`}</h1>
      <input
        type="email"
        name="email"
        onChange={handleInputChange}
        className={style.customerInput}
        placeholder="Введите ваш эл. адрес"
      />
      <input
        type="text"
        name="customerName"
        onChange={handleInputChange}
        className={style.customerInput}
        placeholder="Введите ваше имя"
      />
      <input
        type="tel"
        name="phoneNumber"
        onChange={handleInputChange}
        className={style.customerInput}
        placeholder="Введите ваш телефон"
        pattern="\+7\d{10}"
        title="Введите номер телефона в формате +7XXXXXXXXXX"
        required
      />
      <label htmlFor="telegram" className={style.flexBox}>
        Ваш ник в Телеграм
        <input
          id="telegram"
          type="text"
          name="telegram"
          onChange={handleInputChange}
          className={style.customerInput}
          placeholder="@your_nickname"
        />
      </label>
      <button type="submit" disabled={btnInActive}>
        Записаться
      </button>
      <label htmlFor="terms" className={style.customerInput}>
        <input
          id="terms"
          type="checkbox"
          name="termsChecked"
          onChange={handleInputChange}
          checked={customerFormData.termsChecked}
        />
        Я согласен на обработку моих персональных данных в соответствии с &nbsp;
        <a href="https://satoshkin.ru/privacy">Условиями</a>
      </label>
    </form>
  );
};
