import React, { ReactNode } from 'react';
import s from './PaymentCard.module.scss';

type Props = {
  children: ReactNode;
}

const PaymentCard = (props: Props) => {
  const {
    children,
  } = props;
  return (
    <div className={s.card}>
      {children}
    </div>
  );
};

export default PaymentCard;
